
import { dispatchGetTransactionsList } from "@/store/mlm/actions";
import { readTransactionsResponse } from "@/store/mlm/getters";
import WithdrawalModal from "@/components/Main/Transactions/WithdrawalModal.vue";
import DepositModal from "@/components/Main/Transactions/DepositModal.vue";
import GiveAGiftModal from "@/components/Main/Transactions/GiveAGiftModal.vue";
import TransactionsInfoModal from "@/components/Main/Transactions/TransactionsInfoModal.vue";
import { Component, Vue } from "vue-property-decorator";
import { dispatchGetAllConfigs } from "@/store/main/actions";

import moment from "moment-timezone";

@Component({
  components: {
    WithdrawalModal,
    DepositModal,
    GiveAGiftModal,
    TransactionsInfoModal,
  },
})
export default class Transactions extends Vue {
  public page = 1;
  public searchCommentParams = "";
  public selectedPeriod = "";
  public selectedCurrency = "UNT";
  public selectedType = "";
  public selectedСommentType = "";
  public withdrawModalShow = false;
  public giftModalShow = false;
  public depositModalShow = false;
  public transactionsInfoModal = false;

  public async mounted() {
    dispatchGetAllConfigs(this.$store);
    this.handleUpdateList();
    if (this.$router.currentRoute.query.top_up) {
      this.handleOpenDepositModal();
    }
  }

  public handleOpenWithdrawModal() {
    this.withdrawModalShow = true;
  }

  public handleCloseWithdrawModal(updated: boolean = false) {
    this.withdrawModalShow = false;
    if (updated) {
      this.handleUpdateList();
    }
  }

  public handleOpenGiftModal() {
    this.giftModalShow = true;
  }

  public handleCloseGiftModal(updated: boolean = false) {
    this.giftModalShow = false;
    if (updated) {
      this.handleUpdateList();
    }
  }

  public handleOpenDepositModal() {
    this.depositModalShow = true;
  }

  public handleCloseDepositModal() {
    this.depositModalShow = false;
    this.handleUpdateList();
  }

  public handleChangeType(newType) {
    this.page = 1;
    this.selectedСommentType = newType;
    this.handleUpdateList();
  }

  public async handleChangePage(page) {
    if (this.page !== page) {
      this.page = page;
      this.handleUpdateList();
    }
  }

  public async handleUpdateList() {
    const rowQuery = `?page=${this.page}&page_size=${10}`;
    await dispatchGetTransactionsList(this.$store, {
      filter: {
        period: this.selectedPeriod,
        // currency: this.selectedCurrency,
        search_comment_params: this.searchCommentParams,
        comment_type: this.selectedСommentType,
      },
      rowQuery,
    });
  }

  get transactionsResponse() {
    return readTransactionsResponse(this.$store);
  }

  public async handleTransferTokens() {
    console.log("transfer tokens");
  }

  public isTransferButtonActive(): boolean {
    return this.timeUntilDubaiDeadline.asMilliseconds() <= 0;
  }

  get timeUntilDubaiDeadline() {
    const dubaiTimezone = "Asia/Dubai";
    const deadline = moment.tz("2024-07-02 00:01", dubaiTimezone);
    const now = moment.tz(dubaiTimezone);
    const duration = moment.duration(deadline.diff(now));
    return duration;
  }

  get timeUntilTransfer() {
    const duration = this.timeUntilDubaiDeadline;
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return {
      days: days,
      hours: hours,
      minutes: minutes,
    };
  }
}
